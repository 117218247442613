import { FC } from 'react';
import dynamic from 'next/dynamic';

import { PlatformID } from '@common/clients/api';

import { Fallback } from './atoms/Fallback';

const Lato = dynamic(() => import('./atoms/Lato').then((mod) => mod.Lato));
const Lexia = dynamic(() => import('./atoms/Lexia').then((mod) => mod.Lexia));
const OpenSans = dynamic(() => import('./atoms/OpenSans').then((mod) => mod.OpenSans));
const Oswald = dynamic(() => import('./atoms/Oswald').then((mod) => mod.Oswald));
const Poppins = dynamic(() => import('./atoms/Poppins').then((mod) => mod.Poppins));
const Titillium = dynamic(() => import('./atoms/Titillium').then((mod) => mod.Titillium));
const Yrsa = dynamic(() => import('./atoms/Yrsa').then((mod) => mod.Yrsa));

interface Props {
    isAdmin?: true;
    isStorybook?: true;
    platform: PlatformID;
}

export const FontFaces: FC<Props> = ({ platform, isStorybook, isAdmin }) => {
    if (isStorybook) {
        return (
            <>
                <Lexia />
                <OpenSans />
                <Poppins />
                <Titillium />
                <Lato />
                <Oswald />
            </>
        );
    } else if (isAdmin) {
        return (
            <>
                <Fallback />
                <Poppins />
            </>
        );
    }

    switch (platform) {
        case PlatformID.BR:
            return (
                <>
                    <Fallback />
                    <Lexia />
                    <OpenSans />
                </>
            );
        case PlatformID.GP:
            return (
                <>
                    <Fallback />
                    <Titillium />
                </>
            );
        case PlatformID.VI:
            return (
                <>
                    <Fallback />
                    <OpenSans />
                    <Yrsa />
                </>
            );
        case PlatformID.VN:
        case PlatformID.VP:
            return (
                <>
                    <Fallback />
                    <Lato />
                    <Oswald />
                </>
            );
    }
};
